
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { GameStoreKey } from "@/store";
export default defineComponent({
  name: "Memory-Score",
  setup: () => {
    const { state } = useStore(GameStoreKey);
    return {
      highestRecord: computed(() => state.highestRecord),
    };
  },
});
