
import { defineComponent } from "vue";
import Progress from "./Progress.vue";
import Score from "./Score.vue";

export default defineComponent({
  name: "ScoreBoard",
  components: {
    Progress,
    Score,
  },
});
