
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import { ICard, IStatus } from "@/IType";
import { GameStoreKey } from "@/store";
import Card from "./Card.vue";

export default defineComponent({
  name: "ChessBoard",
  components: { Card },
  setup: () => {
    let lastCard = ref<ICard | null>(null);
    const { state, dispatch, commit } = useStore(GameStoreKey);
    const realtimeStatus = computed(() => state.status);
    const realtimeNonMatchedPairs = computed(() => state.nonMatchedPairs);
    const realtimeCards = computed(() => state.cards);

    const onFlip = (e: ICard) => {
      if (realtimeStatus.value === IStatus.READY) {
        dispatch("updateStatus", IStatus.PLAYING);
      }
      if (!lastCard.value) {
        lastCard.value = e;
        return;
      }
      if (lastCard.value !== e && lastCard.value.name === e.name) {
        lastCard.value = null;
        commit("updateNonMatchedPairs", -1);
        if (!realtimeNonMatchedPairs.value) {
          dispatch("updateStatus", IStatus.PASSED);
        }
        return;
      }

      const savedLastCard = lastCard.value;
      lastCard.value = null;
      dispatch("flipsDelay", {
        timeout: 1000,
        cards: [savedLastCard, e],
      });
    };

    return {
      onFlip,
      cards: realtimeCards,
    };
  },
});
