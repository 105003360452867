import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_ScoreBoard = _resolveComponent("ScoreBoard")!
  const _component_GameStatus = _resolveComponent("GameStatus")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_ChessBoard = _resolveComponent("ChessBoard")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_app_bar, { color: "grey-darken-4" }, {
        default: _withCtx(() => [
          _createVNode(_component_Logo)
        ]),
        _: 1
      }),
      _createVNode(_component_v_navigation_drawer, {
        color: "grey-darken-2",
        permanent: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ScoreBoard),
          _createVNode(_component_GameStatus)
        ]),
        _: 1
      }),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            class: "ma-1",
            color: "grey"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ChessBoard)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}