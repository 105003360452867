import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "score-board" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Progress = _resolveComponent("Progress")!
  const _component_Score = _resolveComponent("Score")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Progress),
    _createVNode(_component_Score)
  ]))
}