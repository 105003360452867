import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9270b1aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "front" }
const _hoisted_2 = { class: "back" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createBlock(_component_v_col, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        class: _normalizeClass(["card rounded-lg", { flip: _ctx.card.flipped }]),
        height: "285",
        width: 285 * 0.75
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "flipper",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.doFlip && _ctx.doFlip(...args)))
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_v_img, {
                src: "/black.svg",
                "aspect-ratio": "0.75"
              })
            ]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_v_img, {
                src: '/' + _ctx.card.name + '.jpg',
                "aspect-ratio": "0.75"
              }, null, 8, ["src"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["width", "class"])
    ]),
    _: 1
  }))
}