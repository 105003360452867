
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { ScoreBoard, ChessBoard, GameStatus, MemoryLogo } from "@/components";

import { GameStoreKey } from "@/store";
import Logo from "./components/Logo.vue";

export default defineComponent({
  name: "Game-Memory",
  setup: () => {
    const { commit } = useStore(GameStoreKey);
    onMounted(() => {
      commit("reset");
    });
  },
  components: { ScoreBoard, ChessBoard, GameStatus, Logo },
});
