import { createApp } from "vue";
import App from "./App.vue";
//import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { GameStore, GameStoreKey } from "./store";

loadFonts();

createApp(App).use(GameStore, GameStoreKey).use(vuetify).mount("#app");
