
import { defineComponent, toRefs, PropType } from "vue";
import { useStore } from "vuex";
import { ICard } from "@/IType";
import { GameStoreKey } from "@/store";

export default defineComponent({
  name: "Memory-Card",
  props: {
    card: {
      type: Object as PropType<ICard>,
      required: true,
    },
  },
  emits: {
    onFlip: (payload: ICard) => {
      return !!payload;
    },
  },
  setup: (props, context) => {
    const { card } = toRefs(props);
    const { commit } = useStore(GameStoreKey);
    const doFlip = (e: MouseEvent) => {
      if (card.value.flipped) {
        return;
      }
      commit("flips", [card.value]);
      context.emit("onFlip", card.value);
    };
    return {
      //card,
      doFlip,
    };
  },
});
