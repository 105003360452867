
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { GameStoreKey } from "@/store";

export default defineComponent({
  name: "Memory-Progress",
  setup: () => {
    const { state } = useStore(GameStoreKey);
    return {
      nonMatchedPairs: computed(() => state.nonMatchedPairs),
    };
  },
});
