
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { GameStoreKey } from "@/store";
import { IStatus } from "@/IType";

export default defineComponent({
  setup: () => {
    const { state, commit } = useStore(GameStoreKey);
    return {
      IStatus,
      status: computed(() => state.status),
      timeCost: computed(() => state.timeCost),
      reset: () => commit("reset"),
    };
  },
});
