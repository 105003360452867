import { createStore } from "vuex";

export * from "./GameStore";
export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
